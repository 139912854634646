* {
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
}

#homeContainer {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

#headContainer {
    margin: 4vh 4vw 6vh 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

#headH1 {
    font-weight: 100;
    font-size: 4vw;
    margin-top: 3vh;
    margin-left: 0.4em;
}

#monogram {
    width: 15vw
}

#coverPicture {
    width: 60vw;
    box-shadow: 0 0 2em 2em rgba(255, 255, 255, 0.6);
}

#watermark {
    font-weight: 100;
    font-size: 4vw;
    width: 0;
    font-style: italic;
    letter-spacing: .1em;
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto;
    overflow: hidden;
    position: fixed;
    top: 25vh;
    left: 10vw;
    color: #6e6f6a;
    animation: typing 2s steps(15, end) infinite;
}

#vertLine {
    position: absolute;
    top: 0;
    left: 0;
}

@media (min-width: 920px) {
    #headH1 {
        font-weight: 100;
        font-size: 2.4em;
        margin-top: 0.8em;
        margin-left: 0.4em;
    }
    
    #monogram {
        width: 6em
    }
    
    #coverPicture {
        width: 31em;
        box-shadow: 0 0 2em 2em rgba(255, 255, 255, 0.6);
    }
}

@keyframes typing {
    from { width: 0 }
    to { width: 60vw }
}
