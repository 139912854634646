.navBar {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.button {
  margin: 1em;
}

