.div {
  width: 5em;
  height: 5em;
  background-color: aqua;
  margin: 1em;
}

.div1:hover ~ .div2 {
  background-color: red;
}
