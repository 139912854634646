@import url("https://fonts.googleapis.com/css2?family=Gideon+Roman&display=swap");

:root {
  --unselected: rgb(92, 92, 92);
  --selectedDefaultColor: rgb(19, 19, 19);
}

.menuParent {
  padding: 0.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  min-height: 95vh;
  overflow: hidden;
}

.menuParent:hover a::before {
  color: var(--unselected);
}

ul {
  position: relative;
  display: flex;
  flex-direction: column;
}

.menuBarEl {
  list-style: none;
  transition: 0.5s;
  /* margin-left: -1em; */
}

.menuBarEl > a {
  position: relative;
  font-size: 4.2em;
  text-decoration: none;
  letter-spacing: 0.05em;
  padding: 0 10px;
  color: transparent;
  height: 0.1em;
  margin: 0;
  /* line-height: 1.2em; */
}

.menuBarEl > a {
  clip-path: inset(1px 2px -2px 1px);
}

.menuBarEl > a::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  color: var(--selectedDefaultColor);
  transform: translateX(-0.12em) translateY(0);
  transition: 0.5s;
}

.menuBarEl > a:hover:before {
  transform: translateX(-0.12em) translateY(-1em);
  opacity: 0;
}

.menuBarEl > a::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  color: var(--selectedDefaultColor);
  transform: translateX(-0.12em) translateY(1em);
  opacity: 0;
  transition: 0.5s;
}

.menuBarEl > a:hover:after {
  transform: translateX(-0.12em) translateY(0);
  opacity: 1;
}

.menuOptions {
  margin: 0.6em;
}

/* Lines Animation */
.lineAnimation {
  margin: 2em;
  position: absolute;
  transform: scale(0.8);
  transition: all 0.3s ease-in-out;
}

#top {
  stroke-dasharray: 435;
  stroke-dashoffset: 435;
}

#bottom {
  stroke-dasharray: 453;
  stroke-dashoffset: 453;
}

#left {
  stroke-dasharray: 332;
  stroke-dashoffset: 332;
}

#right {
  stroke-dasharray: 312;
  stroke-dashoffset: 312;
}

#right,
#top,
#bottom,
#left {
  animation: lineAnim 1s cubic-bezier(0.79, -0.13, 0.19, 1.06) forwards;
}

/* .menuOptions:hover > #right,
#right,
#top,
#bottom,
#left {
  margin: 100em;
  animation: lineAnim 1s cubic-bezier(0.79, -0.13, 0.19, 1.06) forwards;
} */

/* #right:hover,
#top:hover,
#bottom:hover,
#left:hover {
  animation: lineAnim 1s cubic-bezier(0.79, -0.13, 0.19, 1.06) backwards;
} */

@keyframes lineAnim {
  to {
    stroke-dashoffset: 0;
  }
}
