@import url("https://fonts.googleapis.com/css2?family=Radio+Canada:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Mulish", sans-serif;
  font-weight: 300;
  background-color: #dfc590;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  list-style: none;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
